<template>
    <div>
        <van-tabbar fixed route v-model="active" @change="handleChange" active-color="#255144" inactive-color="#A1AEAC"
            class="my-tabbar">
            <van-tabbar-item v-for="(item, index) in data" :to="item.to" :key="index">
                <!-- {{ item.title }} -->
                <span class="tab-name">{{ item.title }}</span>
                <template #icon="data">
                    <img :src="[data.active ? item.icon.active : item.icon.inactive]" class="tab-img" />
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>
<script>
    export default {
        name: 'TabBar',
        props: {
            defaultActive: {
                type: Number,
                default: 0
            },
            data: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        data() {
            return {
                active: this.defaultActive
            }
        },
        methods: {
            handleChange(value) {
                this.$emit('change', value)
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }

    .my-tabbar {
        height: 68px;
        background: #FFFFFF;
        box-shadow: 0px -1px 4px 0px rgba(224, 224, 224, 0.5);

        .tab-name {
            font-size: 12px;
            font-weight: 500;
        }

        .tab-img {
            width: 24px;
            height: 24px;
        }
    }

</style>
