<template>
  <!-- :class="$route.meta.showTab?'padding-bottoom-50':''" -->
  <div id="app" class="app-container">
    <router-view></router-view>
    <div class="layout-footer" v-if="$route.meta.showTab">
      <TabBar :data="tabbars" @change="handleChange" />
    </div>
  </div>
</template>
<script>
  import TabBar from '@/components/TabBar'
  // import { getToken } from '@/utils/auth'
  // import { stringify } from 'querystring'
  export default {
    name: 'AppLayout',
    data() {
      return {
        tabbars: [
          {
            title: '活动',
            to: {
              name: 'activityList'
            },
            // icon: 'home-o'
            active: 0,
            icon: {
              active: require('@/assets/images/star1.png'),
              inactive: require('@/assets/images/star.png'),
            }
          },
          {
            title: '帮助',
            to: {
              name: 'help'
            },
            // icon: 'goods-collect-o'
            active: 1,
            icon: {
              active: require('@/assets/images/notice1.png'),
              inactive: require('@/assets/images/notice.png'),
            }
          },
          {
            title: '订单',
            to: {
              name: 'order'
            },
            // icon: 'chart-trending-o'
            active: 2,
            icon: {
              active: require('@/assets/images/order1.png'),
              inactive: require('@/assets/images/order.png'),
            }
          },
          {
            title: '会员中心',
            to: {
              name: 'user'
            },
            // icon: 'user-o'
            active: 3,
            icon: {
              active: require('@/assets/images/user1.png'),
              inactive: require('@/assets/images/user.png'),
            }
          }
        ]
      }
    },
    components: {
      TabBar
    },
    methods: {
      handleChange(v) {
        console.log('tab value:', v)
      }
    },
    created() {

    }
  }
</script>
<style lang="scss">
  #app {
    min-height: 100vh;
    // font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .view {
    min-height: 100vh;
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  ul,
  ol,
  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  img {
    display: block;
  }

  .clearfix {
    *zoom: 1;
  }

  .clearfix:before,
  .clearfix:after {
    content: '';
    display: table;
  }

  .clearfix:after {
    clear: both;
  }

  .padding-bottoom-50 {
    padding-bottom: 50px;
  }

</style>
