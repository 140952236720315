// 获取设备类型
function getDeviceType() {
  const UA = navigator.userAgent

  const type = {
    isAndroid: UA.indexOf('Android') > -1 || UA.indexOf('Adr') > -1, // Android
    isIOS: !!UA.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // iOS
    isIPhone: UA.indexOf('iPhone') > -1, // iPhone
    isIPad: UA.indexOf('iPad') > -1, // iPad
    isWeiXin: UA.indexOf('MicroMessenger') > -1, // 微信
    isAlipay: UA.indexOf('AlipayClient') > -1 // 支付宝
  }

  return type
}

// 计算像素
function transformPx(value) {
  const scale = window.innerWidth / 375
  const newValue = +(scale * value).toFixed(2)
  return newValue
}

export default {
  getDeviceType,
  transformPx
}
